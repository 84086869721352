import React from 'react';
import { H1, H2, Paragraph } from '@increasecard/typed-components';
import styled from 'styled-components';
import { Container } from '../components/Container';
import { MEDIUM } from '../breakpoints';
import { BenefitsBackground } from '../backgrounds/BenefitsBackground';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  justify-content: center;
  align-items: center;
  padding: 72px 0;
`;

const ContentWrapper = styled(Container)`
  display: grid;
  grid-template-columns: 100%;
  align-items: start;
  justify-content: center;
  grid-column-gap: 100px;
  grid-row-gap: 64px;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (min-width: ${MEDIUM}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Benefit = styled.div`
  display: grid;
  grid-row-gap: 8px;
  padding: 0 64px;

  @media (min-width: ${MEDIUM}) {
    padding: 0;
  }
`;

export function BenefitsSection({ title, benefits }) {
  return (
    <BenefitsBackground>
      <SectionWrapper>
        {title && <H1 style={{ marginBottom: '32px' }}>{title}</H1>}
        <ContentWrapper>
          {benefits.map(b => (
            <Benefit key={b.id}>
              <img alt="" src={b.image?.imageSharp?.publicURL} height="45" />
              <H2
                dangerouslySetInnerHTML={{ __html: b.name }}
                style={{ color: 'white' }}
              />
              <Paragraph
                dangerouslySetInnerHTML={{ __html: b.description }}
                style={{ color: 'white' }}
                weight="normal"
              />
            </Benefit>
          ))}
        </ContentWrapper>
      </SectionWrapper>
    </BenefitsBackground>
  );
}
