import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { RoundedSquare } from './shapes/RoundedSquare';
import { BackgroundBase } from './BackgroundBase';

const OutlineSquare = styled(RoundedSquare).attrs({
  size: '92px',
})`
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colorsNew.product.dark};
  bottom: -40px;
  left: 0;
`;

const SmallDarkSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.product.dark,
  rotation: '60deg',
  size: '22px',
})`
  position: absolute;
  bottom: 22%;
  right: 8px;

  @media (min-width: ${MEDIUM}) {
    bottom: unset;
    top: 80px;
    left: 90%;
  }
`;

const baseBackground = theme => theme.colorsNew.product.regular;

export function BenefitsBackground({ children, variant }) {
  return (
    <BackgroundBase
      baseBackground={baseBackground}
      variant={variant}
      renderBackground={_ => (
        <>
          <OutlineSquare />
          <SmallDarkSquare />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
