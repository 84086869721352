import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Section } from '../components/Section';
import { FeaturesSection } from '../pageSections/FeaturesSection';
import { PartnerHeroSection } from '../pageSections/partner_page/PartnerHeroSection';
import { Layout } from '../layout/Layout';
import { BenefitsSection } from '../pageSections/BenefitsSection';

const sectionMapping = {
  Strapi_ComponentPageSectionsOnlyTextHeroSection: PartnerHeroSection,
  Strapi_ComponentPageSectionsFeaturesSection: FeaturesSection,
  Strapi_ComponentPageSectionsBenefitsSection: BenefitsSection,
};

export default function PartnerPage({ data }) {
  const {
    seoInfo,
    sections,
    partner,
    heroLayout,
    headerLayout,
    footerLayout,
  } = data.strapi.partnerPage;
  return (
    <Layout headerLayout={headerLayout} footerLayout={footerLayout}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      {sections.map(s => {
        return (
          <Section
            backgroundVariant="increaseHome"
            mapping={sectionMapping}
            key={`${s.type}${s.id}`}
            layout={heroLayout}
            image={partner.logo}
            partner={partner}
            {...s}
          />
        );
      })}
    </Layout>
  );
}

export const query = graphql`
  query($pageId: ID!) {
    strapi {
      partnerPage(id: $pageId) {
        heroLayout
        headerLayout
        footerLayout
        partner {
          logo {
            ...Image
          }
        }

        seoInfo {
          pageDescription
          pageTitle
          keywords
        }

        sections {
          type: __typename
          ... on Strapi_ComponentPageSectionsOnlyTextHeroSection {
            id
            title
            subtitle
            cta {
              ...CTA
            }
          }

          ... on Strapi_ComponentPageSectionsBenefitsSection {
            id
            title
            benefits {
              id
              name
              description
              image {
                url
                imageSharp {
                  publicURL
                }
              }
            }
          }

          ... on Strapi_ComponentPageSectionsFeaturesSection {
            id
            features {
              id
              title
              name
              description
              image {
                ...Image
              }
              ctaText
              ctaUrl
            }
          }
        }
      }
    }
  }
`;
