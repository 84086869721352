import React from 'react';
import { Container } from '../../components/Container';
import { Grid as BaseGrid } from '../../components/Grid';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { SuperHeader } from '../../components/Typography';
import { CTAButton } from '../../components/CTAButton';
import { OnlyTextHeroSection } from '../OnlyTextHeroSection';
import { H3 } from '@increasecard/typed-components';
import { MEDIUM } from '../../breakpoints';

const Wrapper = styled.div`
  padding: 40px 0;
  @media (min-width: ${MEDIUM}) {
    padding: 96px 0;
  }
`;

const Grid = styled(BaseGrid)`
  @media (min-width: ${MEDIUM}) {
    grid-auto-flow: column dense;
  }
`;

const ImageWrapper = styled.div`
  grid-column: span 2 / span 2;
  margin-top: 32px;
  @media (min-width: ${MEDIUM}) {
    margin: auto 0;
    grid-column-start: ${({ layout }) =>
      layout === 'imageLeftTextRight' ? '1' : '6'};
    grid-column-end: span 3;
  }
`;
const Title = styled(SuperHeader)`
  margin-bottom: 8px;
`;
const TextWrapper = styled.div`
  grid-column: span 2 / span 2;
  @media (min-width: ${MEDIUM}) {
    grid-column-start: ${({ layout }) =>
      layout === 'imageLeftTextRight' ? '5' : '1'};
    grid-column-end: span 4;
  }
`;

export function PartnerHeroSection({ partner, title, subtitle, cta, layout }) {
  if (partner.logo === null) {
    return <OnlyTextHeroSection title={title} subtitle={subtitle} cta={cta} />;
  }
  return (
    <Wrapper>
      <Container>
        <Grid>
          <TextWrapper layout={layout}>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <H3
              weight="normal"
              dangerouslySetInnerHTML={{ __html: subtitle }}
              style={{ marginBottom: '16px' }}
            />
            {cta && <CTAButton {...cta} />}
          </TextWrapper>
          {partner.logo && (
            <ImageWrapper layout={layout}>
              <GatsbyImage {...partner.logo.imageSharp.childImageSharp} />
            </ImageWrapper>
          )}
        </Grid>
      </Container>
    </Wrapper>
  );
}
